<template>
  <div class="help-detail">
    <radio :show.sync="showStatus" />
    <helloWorld />
    <div class="content-520">
      <p class="helpTop">
        <span></span>
        修改信息
      </p>
      <div class="helpMain">
        <!-- <h3>
          修改资料会后会更新到您的主页
        </h3>
        <div class="data">
          <p class="change-avatar"><span>*</span>用户头像：
            <el-upload
              class="avatar-uploader"
              action="https://jsonplaceholder.typicode.com/posts/"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </p>
          <p style="font-size:12px;padding-left:70px">上传大小不能超过10M，封面类型仅限jpeg jpg png bmp gif格式</p>
          <p class="change-name" style="margin-top:20px"><span>*</span>描述：<el-input style="width:300px" /></p>
          <div style="margin-top:20px;text-align:center;margin-bottom:20px">
            <el-button type="primary" size="small">确定</el-button>
          </div>
        </div> -->
        <!-- 修改密码 -->
        <h3 style="margin-top:50px">
          修改密码
        </h3>
        <div class="data">
          <el-form :model="registerForm" :rules="registerRules" ref="registerForm" label-width="100px" class="demo-ruleForm" style="padding:10px 30px">
            <el-form-item label="原密码" prop="oldPwd">
              <el-input v-model="registerForm.oldPwd" type="password" placeholder="请输入原密码" />
              <!-- <span class="code" @click="tackBtn">{{valiBtn}}</span> -->
            </el-form-item>
            <el-form-item label="新密码" prop="pwd">
              <el-input v-model="registerForm.pwd" type="password" placeholder="请输入新密码" />
              <!-- <span class="code" @click="tackBtn">{{valiBtn}}</span> -->
            </el-form-item>
            <el-form-item label="确认新密码" prop="pwd2">
              <el-input v-model="registerForm.pwd2" type="password" placeholder="请再次输入新密码" />
              <!-- <span class="code" @click="tackBtn">{{valiBtn}}</span> -->
            </el-form-item>
            <el-form-item style="text-align:center">
              <el-button @click="submit('registerForm')" type="primary" size="small" style="width:100%;margin-top:10px">确定</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div> 
  </div>
</template>
<script>
import helloWorld from '@/components/tabBar'
import radio from '@/components/radio'
import { changePwd } from '../../api/index';
import { mapGetters } from 'vuex'
export default {
  name: 'Detail',
  components: {
    helloWorld,
    radio
  },
  mounted() {
    if (!this.accessToken || this.accessToken == '') {
      this.$router.push('./')
      return
    }
  },
  computed: {
    ...mapGetters(['accessToken', 'userCode'])
  },
  data() {
    return {
      showStatus: false,
      imageUrl: '',
      registerForm: {},
      registerRules: {
        oldPwd: [
          { required: true, message: '请输入原密码', trigger: 'blur' },
          { min: 8,  message: '请输入8位或以上的密码', trigger: 'blur' }
        ],
        pwd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 8,  message: '请输入8位或以上的密码', trigger: 'blur' }
        ],
        pwd2: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { required: true, trigger: 'blur', validator: this.validatePass },
          { min: 8,  message: '请输入8位或以上的密码', trigger: 'blur' }
          ]
        },
    }
  },
  methods: {
    submit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
              changePwd(this.registerForm).then(res => {
                if(res.code === 0) {
                  this.$message.success('密码修改成功，请重新登陆');
                  this.$store.dispatch('accessToken','')
                  var _that = this
                  setTimeout(function () {
                    _that.$router.push('./')
                  }, 1000);
                } else {
                  this.$message.error(res.message);
                }
             })
          } else {
            return false;
          }
        });
      },
    validatePass(rule, value, callback) {
      // 这里每一种情况都要callback，不然表单验证会失效  
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (value !== this.registerForm.pwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
      callback()
      }
    },
    handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      }
  }
}
</script>
<style scoped>
.data {
  max-width: 720px;
  margin: auto;
}
.change-name {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  padding-left: 24px;
}
.change-name span{
  color: red;
  margin-right:2px
}
.change-avatar {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  display: flex;
  align-items: flex-start;
  margin-top: 20px
}
.change-avatar span{
  color: red;
  margin-right:2px
}
.content-520 {
  max-width: 888px;
  margin: auto;
  padding-top: 50px;
}
.helpTop{
  display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    color: #1d202b;
    margin-bottom: 40px;
}
.helpTop span{
  width: 4px;
    height: 20px;
    background: #3d61ff;
    display: inline-block;
    margin-right: 10px;
}
.help-detail .helpMain {
    max-width: 840px;
    margin: 0 auto;
    padding: 0 16px;
}
.help-detail .helpMain h3 {
    max-width: 840px;
    font-size: 18px;
    font-weight: 400;
    color: #303030;
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
    position: relative;
    margin-bottom: 20px;
}
.help-detail .helpMain h3::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 345px;
    height: 2px;
    background: #2f54eb;
}
</style>
<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>